import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content:center;
    align-items: center
`;
export const Link = styled.a`

`;
export const Img = styled.img`
    width: 40px;
    heigth: 40px;
`;
